import { atom } from "jotai";
import { graphql, readInlineData } from "react-relay";

import type { atomsCurrentArticleAtomBase$key } from "scmp-app/queries/__generated__/atomsCurrentArticleAtomBase.graphql";
import type { hooksTrackCurrentItemBase$data } from "scmp-app/queries/__generated__/hooksTrackCurrentItemBase.graphql";

export const currentItemAtom = atom<hooksTrackCurrentItemBase$data | null>(null);

export const currentArticleAtom = atom(get => {
  const currentItem = get(currentItemAtom);
  if (!currentItem) return;

  const item = readInlineData<atomsCurrentArticleAtomBase$key>(
    graphql`
      fragment atomsCurrentArticleAtomBase on BaseWithApplicationAndUrlAlias
      @inline
      @argumentDefinitions(customContents: { type: "[CustomContentInput]", defaultValue: [] }) {
        __typename
        ... on Article {
          ...articleSeoArticle @arguments(customContents: $customContents)
          ...contentShareWidgetContent
          ...headerContentBookmarkArticle
          ...headerContentCommentTrigger
          ...helpersCheckIsStyleArticle
          ...helpersUseSharingUrl
          ...hooksTrackPageViewArticle
          ...hooksContentInterestArticle
          ...hooksUseCurrentArticleIdsArticle
          ...hooksUseNotificationReadStatusArticle
          ...onboardingNewsletterSubscriptionArticle
        }
      }
    `,
    currentItem,
  );

  switch (item.__typename) {
    case "Article":
      return item;
    case "%other":
      return;
  }
});
