import { type SocialShareType, theme, useResponsive } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";
import { startTransition, useEffect, useMemo, useState } from "react";

import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import IconShare from "scmp-app/components/header/header-icon/share.svg";
import { currentArticleAtom } from "scmp-app/lib/current-item";

import { StyledDynamicContentShareWidget, StyledDynamicDrawer } from "./styles";

export type Variant = "default" | "magazines-style";

const shareWidgetTypesMap: Record<Variant, SocialShareType[]> = {
  default: [
    "facebook",
    "twitter",
    "whatsapp",
    "email",
    "messenger",
    "line",
    "telegram",
    "copy-link",
  ],
  "magazines-style": [
    "facebook",
    "twitter",
    "whatsapp",
    "email1",
    "messenger",
    "line",
    "telegram",
    "copy-link",
  ],
};

export const HeaderContentShareWidgetDrawer: FunctionComponent = () => {
  const currentArticle = useAtomValue(currentArticleAtom);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const setNextIsOpenDrawer = (nextIsOpenDrawer: boolean) => {
    startTransition(() => {
      setIsOpenDrawer(nextIsOpenDrawer);
    });
  };

  const isTabletUp = useResponsive(theme.breakpoints.up("tablet"), true);
  useEffect(() => {
    setNextIsOpenDrawer(false);
  }, [isTabletUp]);

  const variant: Variant = useMemo(() => {
    if (currentArticle && checkIsStyleArticle(currentArticle)) {
      return "magazines-style";
    }
    return "default";
  }, [currentArticle]);

  const shareWidgetTypes = shareWidgetTypesMap[variant];

  if (!currentArticle) return null;

  return (
    <>
      <IconShare
        height={16}
        width={16}
        onClick={() => {
          setNextIsOpenDrawer(true);
        }}
      />
      <StyledDynamicDrawer
        $variant={variant}
        anchor="bottom"
        open={isOpenDrawer}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
        }}
        title="Share"
        withClose
        onClose={() => setNextIsOpenDrawer(false)}
      >
        <StyledDynamicContentShareWidget
          extraProps={{
            copyLink: {
              variant: "label",
            },
          }}
          position="article_adhesive"
          reference={currentArticle}
          types={shareWidgetTypes}
          showLabel
          onClick={() => setNextIsOpenDrawer(false)}
        />
      </StyledDynamicDrawer>
    </>
  );
};

HeaderContentShareWidgetDrawer.displayName = "HeaderContentShareWidgetDrawer";
