import styled from "@emotion/styled";

import { ArticleCommentsTrigger } from "scmp-app/components/article/article-comments-trigger";

export const StyledArticleCommentsTrigger = styled(ArticleCommentsTrigger)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  inline-size: 18px;
  block-size: 100%;
`;
