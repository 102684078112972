import dynamic from "next/dynamic";

import type { Props as DrawerProps } from "scmp-app/components/common/drawer";
import type { Props as ContentShareWidgetProps } from "scmp-app/components/content/content-share-widget";

export const DynamicDrawer = dynamic<DrawerProps>(() =>
  import("scmp-app/components/common/drawer").then(module => module.Drawer),
);

export const DynamicContentShareWidget = dynamic<ContentShareWidgetProps>(() =>
  import("scmp-app/components/content/content-share-widget").then(
    module => module.ContentShareWidget,
  ),
);
